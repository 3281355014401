import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import LogoLight from "../images/logo-light-60.png"
import classNames from 'classnames'

const Header = ({ siteTitle }) => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <nav id={"ee-nav"} className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to={"/"}>
          <img src={LogoLight} alt="88bits" />
        </Link>

        <a role="button" aria-label="menu" aria-expanded="false" data-target="ee-navbar-menu" href="#"
           className={classNames("navbar-burger", "burger", {"is-active": menuActive})}
           onClick={() => setMenuActive(!menuActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="ee-navbar-menu" className={classNames("navbar-menu", {"is-active": menuActive})} >
        <div className="navbar-end">
          <Link to={"/about"} className={"navbar-item"}>About</Link>
          <Link to={"/services"} className={"navbar-item"}>Services</Link>
          <Link to={"/blog"} className={"navbar-item"}>Blog</Link>
          <Link to={"/contact"} className={"navbar-item"}>
            <button id={'say-hi-button'} className={'button is-rounded'}>Say Hi!</button>
          </Link>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
